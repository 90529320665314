<style scoped>
table.inshadow > tbody {
  box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-webkit-box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.6) inset;
-moz-box-shadow: -0x 0px 6px 0px rgba(0,0,0,0.6) inset;
}
.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
tr {
  cursor: pointer;
  background-color: unset;
}
th {
  border-bottom: 1px solid #343a40;
}
.fsicon {
  vertical-align: -0.4em;
  color: #c9d1d9;
}
.fsicon.folder {
  color: #1769aa;
}
.fsicon.device {
  color: #8db4e588;
}
.file-comment{
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
td.actions:hover {
  opacity: 1 !important;
}
</style>
<template>
  <table class="table table-hover inshadow ">
    <thead class="bg-dark">
      <tr class="prevent-select">
        <th @click.prevent="toggleOrderbyName()">
          Nombre
          <Icon v-if="orderBy=='filename' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='filename' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th class="d-none d-md-table-cell" @click.prevent="toggleOrderbyYear()">
          A&ntilde;o
          <Icon v-if="orderBy=='date' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='date' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th class="d-none d-md-table-cell" @click.prevent="toggleOrderbyMonth()">
          Mes
          <Icon v-if="orderBy=='date' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='date' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th class="d-none d-md-table-cell" @click.prevent="toggleOrderbySize()">
          Tama&ntilde;o
          <Icon v-if="orderBy=='file.size' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='file.size' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th class="d-none d-md-table-cell" @click.prevent="toggleOrderbyLastActivity()">
          <Icon icon="mdi:tray-upload" :inline="true" />
          <Icon v-if="orderBy=='createdAt' && orderAsc" icon="ic:twotone-keyboard-arrow-up" :inline="true" style="vertical-align: 0.1em;" />
          <Icon v-if="orderBy=='createdAt' && !orderAsc" icon="ic:twotone-keyboard-arrow-down" :inline="true" style="vertical-align: 0.1em;" />
        </th>
        <th style="width: 40px">
          &nbsp;
        </th>
        <!-- <th style="width: 40px">
          <svg data-v-4ef421ca="" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="1.25em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 640 512" class="text-lightgray"><path data-v-4ef421ca="" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64s-64 28.7-64 64s28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6c40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32S208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" fill="currentColor" /></svg>
        </th> -->
      </tr>
    </thead>
    <tbody>
      <tr v-if="parentDir">
        <td colspan="6" @click.prevent="$emit('directory-clicked', {})">
          <a href="#" class="hover-info d-inline-block" @click.prevent="$emit('directory-clicked', {})">
            <Icon icon="icon-park-solid:back" height="1.7em" class="text-default mr-1 fsicon" />
            {{ parentDir }}
          </a>
        </td>
      </tr>
      <tr v-for="directory in directories" :key="directory._id">
        <td colspan="6" @click.prevent="$emit('directory-clicked', directory)">
          <a href="#" class="hover-info d-inline-block" @click.prevent="$emit('directory-clicked', directory)">
            <Icon icon="fa-solid:folder" height="1.7em" class="text-info mr-1 fsicon" />
            {{ directory.name }}
          </a>
        </td>
      </tr>
      <tr v-if="files.length<1">
        <td colspan="6" class="text-center">
          {{ stringError ? stringError : "" }}
          No hay archivos aquí...
        </td>
      </tr>
      <tr v-for="it in files" :key="it._id">
        <!-- <td>1.</td> -->
        <td>
          <a href="#" class="hover-info d-inline-block" @click.prevent="onItemClick(it,...arguments)">
            <Icon :icon="it.p_icon" height="1.7em" class="mr-1 fsicon" />
            {{ it.filename }}
          </a>
          <Icon v-if="isShared(it.users)" icon="fa-solid:users" inline="true" class="ml-1" :class="{'text-warning': isShared(it.users)}" />
          <small class="text-muted file-comment">{{ it.p_comment }}</small>
          <small class="text-muted d-none d-xs-block d-sm-block d-md-none">
            {{ it.p_humanFileSize }}
          </small>
          <small class="text-muted d-none d-xs-block d-sm-block d-md-none">
            {{ it.p_dateUpload }}
          </small>
        </td>
        <td class="text-nowrap d-none d-md-block" style="width: 5%;">
          {{ it.p_year }} &nbsp; &nbsp;
        </td>
        <td class="text-nowrap d-none d-md-table-cell" style="width: 10%;">
          {{ it.p_month }}
        </td>
        <td class="text-nowrap d-none d-md-block" style="width: 10%;">
          {{ it.p_humanFileSize }}
        </td>
        <td class="text-nowrap d-none d-md-table-cell" style="width: 1%;">
          <small class="text-muted">
            {{ it.p_dateUpload }}
          </small>
        </td>
        <td class="pt-0 actions" style="opacity: 0.05;">
          <div v-if="rootfolder !== 'trash'" class="btn-group ">
            <button type="button" class="btn btn-sm btn-link d-none d-md-block" @click.prevent="onFavClicked(it)">
              <Icon :icon="it.p_favicon" style="vertical-align: -1.25em;" :class="{ 'text-yellow':it.p_favicon=='ic:sharp-star'}" width="2em" height="2em" />
            </button>
            <button type="button" class="btn btn-sm btn-link" @click.prevent="onDownloadClicked(it)">
              <Icon icon="ic:outline-cloud-download" width="2em" style="vertical-align: -1.25em;" height="2em" />
            </button>
            <button type="button" class="btn btn-sm btn-link d-none d-md-block" @click.prevent="onEditNameClicked(it)">
              <Icon icon="ic:sharp-mode-edit" style="vertical-align: -1.25em;" height="2em" />
            </button>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-md btn-link dropdown-toggle"
                data-toggle="dropdown"
                :class="{'text-warning': isShared(it.users)}"
              >
                <Icon v-if="isShared(it.users)" icon="fa-solid:users" />
                <Icon v-else icon="fa-solid:user" />
              </button>
              <div class="dropdown-menu">
                <h6 class="dropdown-header">
                  <span class="badge badge-pill badge-gray text-black">Compartir con:</span>
                </h6>
                <a
                  v-for="item in sharedUsers(it)"
                  :key="item.id"
                  class="dropdown-item" :class="{'text-primary': item.sharing}" href="#"
                  @click.prevent="toggleSharing(it, item)"
                >
                  <svg v-if="item.sharing" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" style="vertical-align: -0.125em;" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16">
                    <g fill="currentColor">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417L5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </g>
                  </svg>
                  {{ item.email }}
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" @click.prevent="onItemClick(it)">Enlace público</a>
              </div>
            </div>
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-md btn-link dropdown-toggle"
                data-toggle="dropdown"
              >
                <Icon icon="bi:three-dots-vertical" />
              </button>
              <div class="dropdown-menu">
                <h6 class="dropdown-header text-left pl-3">
                  <span class="badge badge-pill badge-gray text-black">Editar</span>
                </h6>
                <a class="dropdown-item" href="#" @click.prevent="onEditNameClicked(it)">Nombre</a>
                <a class="dropdown-item" href="#" @click.prevent="onEditCommentClicked(it)">Comentario</a>
                <a class="dropdown-item" href="#" @click.prevent="onEditYearClicked(it)">A&ntilde;o</a>
                <a class="dropdown-item" href="#" @click.prevent="onEditMonthClicked(it)">Mes</a>
                <a class="dropdown-item" href="#" @click.prevent="onMoveClicked(it)">
                  <Icon icon="ic:baseline-drive-file-move-rtl" />
                  Mover a
                </a>
                <!-- <a class="dropdown-item" href="#">Editor de tags</a>
                <a class="dropdown-item" href="#">Enviar</a>
                <a class="dropdown-item" href="#">Detalles</a> -->
                <div class="dropdown-divider"></div>
                <a class="dropdown-item text-warning" href="#" @click.prevent="onDeleteClicked(it)">
                  <Icon icon="bi:trash-fill" />
                  Eliminar
                </a>
              </div>
            </div>
          </div>
          <div v-else class="btn-group">
            <button type="button" class="btn btn-sm btn-link" @click.prevent="onRestoreClicked(it)">
              <Icon icon="la:trash-restore" width="2em" style="vertical-align: -1.25em;" height="2em" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { format, parseISO } from "date-fns";
import esmx from "date-fns/locale/es";
import { Icon } from "@iconify/vue2";
import { humanFileSize } from "@/services/Tools";
import ServiceApi from "./Folders.service";

// Lazy loaders
// const CommentsPane = () => import("@/components/CommentsPane.vue");

export default {
  components: {
    Icon,
    // CommentsPane,
  },
  props: {
    apiurl: {
      type: String,
      default: "/api/folders"
    },
    users: {
      type: Array,
      default: () => [] // array de {id, email, name}
    },
    tree: {
      type: Array,
      default: () => [] // el arbol de carpetas entero
    },
    rootfolder: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      files: {
        type: Array,
        default: () => [] // array de {id, label, item}
      },
      orderBy: "createdAt",
      orderAsc: false,
      mimeTypesIcons: {
        "application/pdf": "fa-solid:file-pdf",
        "application/msword": "fa-solid:file-word",
        "application/vnd.ms-excel": "fa-solid:file-excel",
        "image/jpeg": "fa-solid:file-image",
        "image/png": "fa-solid:file-image",
        "image/gif": "fa-solid:file-image",
        "application/json": "fa-solid:file-code"
      },
      stringError: ""
    };
  },
  computed: {
    isOpen() {
      const { status = "open" } = this.item;
      return status === "open";
    },
    directories() {
      const [dir = {}] = this.tree.filter((it) => it.path === this.rootfolder);
      const { children = [] } = dir;
      return children;
    },
    parentDir() {
      const dirs = this.rootfolder.split("/");
      if (dirs.length < 2) {
        return "";
      }
      return "...";
    }
  },

  watch: {
    apiurl: {
      immediate: true,
      // eslint-disable-next-line no-unused-vars
      handler(val, old) {
        // console.log("Reload files", old, val);
        this.fillInitial();
      }
    }
  },
  methods: {
    toggleOrderbyName() {
      if (this.orderBy === "filename") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "filename";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyLastActivity() {
      if (this.orderBy === "createdAt") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "createdAt";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbySize() {
      if (this.orderBy === "file.size") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "file.size";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyYear() {
      if (this.orderBy === "date") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "date";
      this.orderAsc  = true;
      this.fillInitial();
    },
    toggleOrderbyMonth() {
      if (this.orderBy === "date") {
        this.orderAsc = !this.orderAsc;
        this.fillInitial();
        return;
      }
      this.orderBy = "date";
      this.orderAsc  = true;
      this.fillInitial();
    },
    onItemClick(it) {
      this.$emit("file-clicked", it);
    },
    onFavClicked(item) {
      const { _id = "", flag = "" } = item ?? {};
      this.$emit("file-fav", _id, flag);
    },
    onDownloadClicked(item) {
      const { _id = "", file = {} } = item ?? {};
      const { _id: fid = "" } = file ?? {};
      this.$emit("file-download", _id, fid);
    },
    onEditNameClicked(item) {
      const { _id = "", filename = "" } = item ?? {};
      // console.log("onEditNameClicked", filename, item);
      const ext = this.getExtension(filename);
      let to = filename.indexOf(ext);
      if (to < 3) {
        to = filename.length;
      }
      const name = filename.substring(0, to);
      // console.log("onEditNameClicked", name);
      this.$swal({
        // position: "bottom-end",
        icon: "question",
        title: "Cambiar nombre",
        input: "text",
        // inputLabel: "Nombre de archivo",
        html: `Archivo: <strong>${filename}</strong>`,
        inputValue: name,
        showConfirmButton: true,
        showCancelButton: true,
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "¡Es necesario un nombre de archivo!";
          }
          if (value.length < 4) {
            return "¡Es necesario un nombre con 4 letras!";
          }
          const fname = `${value.trim()}${ext}`;
          this.$emit("file-field-change", _id, "filename", fname);
        }
      });
    },
    onEditCommentClicked(item) {
      const { _id = "", p_comment = "", filename = "" } = item ?? {};
      // console.log("onEditCommentClicked", _id, p_comment);
      this.$swal({
        title: "Cambiar comentario",
        icon: "question",
        input: "textarea",
        // inputLabel: "Comentario:",
        html: `Archivo: <strong>${filename}</strong>`,
        inputValue: p_comment,
        inputPlaceholder: "Escribe comentario",
        showConfirmButton: true,
        confirmButtonText: "OK",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "¡Es necesario comentario para el archivo!";
          }
          if (value.length < 4) {
            return "¡Es necesario un comentario de 4 letras!";
          }
        }
      }).then((result) => {
        console.log(result);
        if (result.isConfirmed) {
          console.log("file-field-change: comment,", _id, result.value);
          this.$emit("file-field-change", _id, "comment", result.value);
        }
      });
    },
    onEditYearClicked(item) {
      const { _id = "", date = null, filename = "" } = item ?? {};
      // console.log("onEditYearClicked", _id, date);
      const dateFileOrig = new Date(date);
      const year = dateFileOrig.getFullYear();
      const cyear = (new Date()).getFullYear();
      const years = [cyear, cyear, cyear, cyear, cyear, cyear].map((y, idx) => y - idx)
        .reduce((acc, curr) => {
          acc[curr] = curr;
          return acc;
        }, {});
      // console.log(years);
      this.$swal({
        title: "Cambiar año",
        icon: "question",
        input: "select",
        inputLabel: "",
        inputValue: year,
        inputPlaceholder: "",
        html: `Archivo: <strong>${filename}</strong>`,
        inputOptions: years,
        // html: "Seleccionar año",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "Seleccione un año";
          }
        }
        // eslint-disable-next-line consistent-return
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          const yearConfirm = parseInt(result.value, 10);
          dateFileOrig.setFullYear(yearConfirm);
          // console.log("file-field-change: date,", _id, dateFileOrig.toISOString());
          this.$emit("file-field-change", _id, "date", dateFileOrig.toISOString());
        }
      });
    },
    onEditMonthClicked(item) {
      const { _id = "", date = null, filename = "" } = item ?? {};
      // console.log("onEditMonthClicked", _id, date);
      const months = [
        "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre",
        "Octubre", "Noviembre", "Diciembre"
      ].reduce((acc, curr, idx) => {
        acc[idx] = curr;
        return acc;
      }, {});
      const dateFileOrig = new Date(date);
      const month = dateFileOrig.getMonth();

      // console.log(months);
      this.$swal({
        title: "Cambiar mes",
        icon: "question",
        input: "select",
        inputLabel: "",
        html: `Archivo: <strong>${filename}</strong>`,
        inputValue: month,
        inputPlaceholder: "",
        inputOptions: months,
        // html: "Seleccionar año",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
        // eslint-disable-next-line consistent-return
        inputValidator: (value) => {
          if (!value) {
            return "Seleccione un mes";
          }
        }
        // eslint-disable-next-line consistent-return
      }).then((result) => {
        // console.log(result);
        if (result.isConfirmed) {
          const monthConfirm = parseInt(result.value, 10);
          dateFileOrig.setMonth(monthConfirm);
          // console.log("file-field-change: date,", _id, dateFileOrig.toISOString());
          this.$emit("file-field-change", _id, "date", dateFileOrig.toISOString());
        }
      });
    },
    onDeleteClicked(item) {
      this.$emit("file-delete", item);
    },
    onRestoreClicked(item) {
      this.$emit("file-restore", item);
    },
    onMoveClicked(item) {
      this.$emit("file-move", item);
    },
    fillInitial() {
      console.log("Refilling from server", this.apiurl);
      ServiceApi.find(this.apiurl, { per_page:100, sort: `${this.orderBy}|${this.orderAsc ? "asc" : "desc"}` })
        .then(res => {
          this.stringError = "";
          if (!res) {
            console.log("Got empty");
            return;
          }
          // console.log("Got", res);

          const parsedRows = res.rows.map(it => {
            const {
              createdAt = null, file = {}, flag = "", date: fileDate = null
            } = it;
            const { size = 0, contentType = "", description = "" } = file ?? {};

            let dateUpload = "-";
            if (createdAt) {
              const dateOrig = parseISO(createdAt);
              dateUpload = format(dateOrig, "dd MMM, yy HH:mm", { locale: esmx });
            }

            let month = "-";
            let year = "-";
            if (fileDate) {
              const dateFileOrig = parseISO(fileDate);
              month = format(dateFileOrig, "MMMM", { locale: esmx });
              year = format(dateFileOrig, "yyyy", { locale: esmx });
            }

            const icon = this.mimeTypesIcons[contentType] ?? "fa-solid:file";

            return {
              ...it,
              p_dateUpload: dateUpload,
              p_humanFileSize: humanFileSize(size),
              p_icon: icon,
              p_comment: description,
              p_favicon: flag === "info" ? "ic:sharp-star" : "ic:sharp-star-border-purple500",
              p_month: month,
              p_year: year
            };
          });
          // console.log("Parsed", parsedRows);
          this.files = parsedRows;
        })
        .catch(e => {
          const { message = "Server error", response = {} } = e;
          const { data: dataResponse = {}, status = 400 } = response;
          const { error_string: errStr = message } = dataResponse;
          console.log("Folder fill FAILED ", status, errStr);
          this.files = [];
          this.stringError = `(${errStr}, CODE: ${status})`;
        });
    },
    refresh() {
      this.fillInitial();
    },
    sharedUsers(folder) {
      const { users: currentUsers = [] } = folder ?? {};
      // console.log("USERS", currentUsers, this.users);
      const currentUserIds = currentUsers.map(it => it._id);
      return this.users.map(it => {
        const { id, email, name } = it;
        // console.log("SH", id, currentUsers);
        const sharing = currentUserIds.indexOf(id) > -1;
        return {
          id,
          email,
          name,
          sharing
        };
      });
    },
    toggleSharing(folder, user) {
      // contiene el actual status de sharing y hay que invertirlo
      const { _id = null } = folder ?? {};
      console.log("Toggle sharing of:", _id, user);
      this.$emit("file-sharing", _id, user);
    },
    isShared(users) {
      const u = users ?? [];
      return u.length > 1;
    },
    getExtension(filename) {
      const splitName = filename.split(".");
      let ext = "";
      if (splitName.length > 1) {
        const [first = ""] = splitName.slice(-1);
        ext = first;
      }
      if (ext.length < 2) {
        ext = "";
      }
      else {
        ext = `.${splitName.pop()}`;
      }
      return ext;
    }
  },

  mounted() {}
};
</script>
